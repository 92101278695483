import React from "react";
import useStyles from "./style";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//@ts-ignore
import aboutImage from "../../Images/Raf.jpg";

const About = () => {
  const classes = useStyles();

  const handleResumeButtonClick = () => {
    window.open(
      "https://drive.google.com/file/d/1FtkaIGcF6lmh3iYtI8JfoukPhKc30ziq/view?usp=drive_link",
      "_blank"
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.title}>
        <h1>
          <FontAwesomeIcon icon={faUserAlt} /> About{" "}
          <span style={{ color: "#8C00FF" }}>Me</span>
        </h1>
      </div>
      <div className={classes.imageContainer}>
        <div>
          <img src={aboutImage} className={classes.image} />
        </div>

        <div className={classes.aboutMeText}>
          <p style={{ fontSize: "30px" }}>
            <b>
              I'm <span style={{ color: "#8C00FF" }}>Rafik</span>
            </b>
          </p>
          <p style={{ color: "#8C00FF", fontSize: "25px" }}>
            <b>Full-Stack Developer</b>
          </p>
          <p style={{ fontSize: "20px" }}>
            As a software engineer with a strong foundation in computer
            engineering from the National Polytechnic University of Armenia, I
            have a over three years of versatile experience. From crafting
            features for the Picsart app to deploying web solutions for Code
            Republic Lab's clients, I've honed my skills in development. I also
            have experience as a technical support specialist at Ucom, a
            telecommunications company, where I provided technical assistance
            and troubleshooting to customers. My dedication to user-centric
            innovation and continuous learning propels me toward achieving
            excellence as a senior software engineer, enthusiastically taking on
            impactful projects with expertise.
          </p>

          <p style={{ fontSize: "20px" }}>
            <b style={{ color: "#8C00FF" }}>Email: </b>{" "}
            rafomnacakanyan99@gmail.com
          </p>
          <p style={{ fontSize: "20px" }}>
            <b style={{ color: "#8C00FF" }}>Place: </b> Yerevan, Armenia
          </p>

          <button
            className={classes.resumeButton}
            onClick={handleResumeButtonClick}
          >
            Resume
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
