import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    // height: "100vh",
  },
  aboutHeight: {
    height: "100vh",
  },
  experienceHeight: {
    height: "90vh",
  },
  homeHeight: {
    height: "auto",
  },

  "@media screen and (min-width: 600px) and (max-width: 1200px)": {
    aboutHeight: {
      height: "auto",
    },
    experienceHeight: {
      height: "auto",
    },
    homeHeight: {
      height: "900px",
    },
  },
});

export default useStyles;
